import React from 'react';

const EstimatedReadingTime = ({ text }) => {
  let wordcount = text.split(' ').length;
  let readingTimeMin = Math.ceil(wordcount / 200);

  return (
    <>
      {wordcount} Wörter - Lesezeit: ca. {readingTimeMin} Minuten
    </>
  );
};

export default EstimatedReadingTime;
