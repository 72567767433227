import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~components/layouts/Default';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse, { attributesToProps } from 'html-react-parser';
import H2 from '~components/typography/H2';
import Button from '~components/common/Button';
import EstimatedReadingTime from '~components/common/EstimatedReadingTime';
import Breadcrumb from '~components/common/Breadcrumb';

const Blog = ({ data, location }) => {
  const { blog } = data;
  const image = getImage(blog.remoteTitelbild);

  const text = parse(blog.text, {
    replace: (domNode) => {
      if (domNode.name === 'img') {
        domNode.attribs.src = `https://cms.entspannungshelden.de/${domNode.attribs.src}`;
        const props = attributesToProps(domNode.attribs);
        return (
          <img
            height={domNode.attribs.height}
            src={domNode.attribs.src}
            width={domNode.attribs.width}
            title={domNode.attribs.alt}
            alt={domNode.attribs.alt}
            loading="lazy"
            className="rounded-xl"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        );
      }
    }
  });

  const date = new Date(blog.datum);

  return (
    <Layout
      location={location}
      title={blog.titel_seo}
      desc={blog.einleitung_seo}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${blog.facebook.childImageSharp.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={blog.facebook.childImageSharp.gatsbyImageData.width}
      imageFacebookHeight={blog.facebook.childImageSharp.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${blog.twitter.childImageSharp.gatsbyImageData.images.fallback.src}`}
      active="Blog"
    >
      <article className="relative mb-4 px-4">
        <Breadcrumb pages={[{ title: 'Wissen', link: '/wissen/' }, { title: 'Blog', link: '/blog/' }, { title: blog.titel }]} className="my-4 flex justify-center" />
        <div className="mx-auto max-w-5xl py-5 text-center">
          <GatsbyImage image={image} className="mx-auto rounded-xl" alt={blog.titel} />
        </div>
        <div className="mx-auto max-w-3xl text-lg">
          <header className="my-8">
            <div className="space-y-5 text-center">
              <dl className="space-y-2">
                <div>
                  <dt className="sr-only">Veröffentlicht am</dt>
                  <dd className="text-base font-medium leading-6 text-brand-gray">
                    <time dateTime={blog.datum.toString()}>
                      {date.toLocaleDateString('de-DE', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </time>
                  </dd>
                </div>
                <div>
                  <span className="text-base font-medium leading-6 text-brand-gray">
                    <EstimatedReadingTime text={blog.text} />
                  </span>
                </div>
              </dl>
              {Array.isArray(blog.downloads) && blog.downloads.length > 0 && blog.downloads.some((entry) => entry.value) && (
                <div className="mx-auto my-12 max-w-md px-3 py-3 text-brand-gray">
                  <p className="font-display font-bold leading-relaxed">Freebies</p>
                  <div className="grid gap-3 pt-2">
                    {blog.downloads.map((download, i) => {
                      if (download.value !== null) {
                        return <Button text={download.value.title} href={`https://cms.entspannungshelden.de/storage/uploads${download.value.path}`} download target="_blank" key={download.value.title} />;
                      }
                      return <React.Fragment key={i}></React.Fragment>;
                    })}
                  </div>
                </div>
              )}
            </div>
          </header>
        </div>

        <div className="prose prose-lg mx-auto mt-6 max-w-3xl">
          <h1>{blog.titel}</h1>
          <p>{blog.einleitung}</p>

          {text}
        </div>
      </article>

      <div className="mx-auto max-w-3xl px-4 py-16 text-center">
        <H2 className="text-3xl font-extrabold">
          <span className="block text-4xl">Noch nicht genug?</span>
          <span className="block text-2xl text-brand">Besuche unseren Podcast oder lese weiter im Blog!</span>
        </H2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex">
            <Button to="/podcast/" text="Zum Podcast" />
          </div>
          <div className="ml-3 inline-flex">
            <Button to="/blog/" text="Weiter im Blog" type="transparent" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    blog(id: { eq: $id }) {
      titel
      id
      slug_slug
      titel_seo
      remoteTitelbild {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      facebook: remoteTitelbild {
        childImageSharp {
          gatsbyImageData(height: 630, width: 1200)
        }
      }
      twitter: remoteTitelbild {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      public
      einleitung_seo
      einleitung
      datum
      text
      downloads {
        value {
          path
          title
          mime
          description
          size
          image
          video
          audio
          archive
          document
          code
          created
          modified
          _by
          folder
          _id
        }
      }
    }
  }
`;
